import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

import { BsInstagram } from 'react-icons/bs';
import { FaGithub, FaStackOverflow, FaMedium } from 'react-icons/fa';
import { FaGripLinesVertical } from "react-icons/fa";
// import { FaGripLines } from "react-icons/fa";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {/* <b>• </b> */}
        <FaGripLinesVertical />
        <img src={images.logo} alt="logo" />
        {/* <b> •</b> */}
        <FaGripLinesVertical />
        </div>
      <ul className="app__navbar-links">
        {['home', 'about', 'projects', 'skills', 'contact'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} className='cross'/>
            <ul>
              {['home', 'about', 'projects', 'skills', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
              <hr color='black' className='linee'/>

              <u className='social-icons'>
            <a href="https://github.com/programmermanan" target="_blank" rel="noopener noreferrer">
  <FaGithub className="socialMediaIcons" color='black'/> 
</a>
<a href="https://www.instagram.com/manan._.m" target="_blank" rel="noopener noreferrer">
  <BsInstagram className="socialMediaIcons" color='black'/>
</a>
<a href="https://medium.com/@myselfmanan" target="_blank" rel="noopener noreferrer">
  <FaMedium className="socialMediaIcons" color='black'/>
</a>
<a href="https://stackoverflow.com/users/23436753/programmermanan" target="_blank" rel="noopener noreferrer">
  <FaStackOverflow className="socialMediaIcons" color='black'/>
</a>
</u>
            </ul>
          </motion.div>
        )}
        
      </div>
    </nav>
  );
};

export default Navbar;