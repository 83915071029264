import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

import { BsInstagram } from 'react-icons/bs';
import { FaGithub, FaStackOverflow, FaMedium } from 'react-icons/fa';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Let’s Build <span>Something Amazing</span> Together!</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:contact@programmermanan.com" className="p-text">contact@programmermanan.com</a>
        </div>
        {/* <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (123) 456-7890" className="p-text">+1 (123) 456-7890</a>
        </div> */}
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
      <div className="copyright">
          <p className="p-text">©2024 programmermanan.com</p>
          <p className="p-text">All rights reserved</p>
      </div>
      <div className='social-icons'>
      <div className="social-icons-container">
  <div className="social-icon">
    <a href="https://github.com/programmermanan" target="_blank" rel="noopener noreferrer">
      <FaGithub />
    </a>
  </div>
  <div className="social-icon">
    <a href="https://www.instagram.com/manan._.m" target="_blank" rel="noopener noreferrer">
      <BsInstagram />
    </a>
  </div>
  <div className="social-icon">
    <a href="https://medium.com/@myselfmanan" target="_blank" rel="noopener noreferrer">
      <FaMedium />
    </a>
  </div>
  <div className="social-icon">
    <a href="https://stackoverflow.com/users/23436753/programmermanan" target="_blank" rel="noopener noreferrer">
      <FaStackOverflow />
    </a>
  </div>
</div>
</div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__primarybg',
);
