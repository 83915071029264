import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaGithub, FaStackOverflow, FaMedium } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div className="social-icon">
      <a href="https://github.com/programmermanan" target="_blank" rel="noopener noreferrer">
        <FaGithub />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://www.instagram.com/manan._.m" target="_blank" rel="noopener noreferrer">
        <BsInstagram />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://medium.com/@myselfmanan" target="_blank" rel="noopener noreferrer">
        <FaMedium />
      </a>
    </div>
    <div className="social-icon">
      <a href="https://stackoverflow.com/users/23436753/programmermanan" target="_blank" rel="noopener noreferrer">
        <FaStackOverflow />
      </a>
    </div>
  </div>
);

export default SocialMedia;
